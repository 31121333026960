import React, {useState} from 'react';
import { fetchMovies } from '../api/FetchMovie';
import ErrorAlert from './ErrorAlert';
import MovieDetail from './MovieDetail';

function MoviePortal() {

    const [searchInput, setSearchInput] = useState("");
    const [enteredText, setEnteredText] = useState("");
    const [movies, setMovies] = useState([]);
    const [error, setError] = useState(null);

    function handleInput(event) {
        setSearchInput(event.target.value);
    }

    function onSearchEntered(e) {
        e.preventDefault();
        fetchMovies(searchInput, setMovies, setError, () => setEnteredText(searchInput));
    }

    return (
      <>
        <div className="container-fluid mt-3">
      <form className="d-flex" onSubmit={onSearchEntered}>
        <input className="form-control me-sm-2" type="search" placeholder="Search for a movie..." value={searchInput} onChange={handleInput} />
        <button className="btn btn-secondary my-2 my-sm-0" type="submit">Search</button>
      </form>
      </div>
      {error && <ErrorAlert error={error} searchTerm={enteredText}/>}
      {movies.length > 0 && <p className='text-dark'>Showing {movies.length} movies for '{enteredText}'</p> }
      {/* <p>{enteredText}</p> */}
      {/* {JSON.stringify(movies)} */}
      {/* {error}; */}
      {movies.map((movie) => (
        <MovieDetail key={movie.imdbID} movie={movie} />
      ))}
  </>
    )
}

export default MoviePortal;