import "bootstrap/dist/js/bootstrap.min.js"
import "bootswatch/dist/minty/bootstrap.min.css";
import Header from "./components/Header";
import MoviePortal from "./components/MoviePortal";

function App() {
  return (
    <div>
      <Header />
      <div className="container">
        <MoviePortal />
      </div>
    </div>
  );
}

export default App;
