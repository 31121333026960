export const fetchMovies = async (searchText, moviesCallback, errorCallback, finallyCallback) => {
    try {
        const response = await fetch (`https://www.omdbapi.com/?s=${searchText}&apikey=e15149d8&type=movie`);
        const data = await response.json();

        if (data.Response === "True") {
            // console.log(data.Search);
            const movieDetailsPromises = data.Search.map((movie) => fetchMovieDetails(movie.imdbID, errorCallback));
            const movieDetails = await Promise.all(movieDetailsPromises);
            
            moviesCallback(movieDetails);
            errorCallback(null);
        } else {
            moviesCallback([]);
            errorCallback(data.Error);
        }
    } catch (err) {
        errorCallback("An error occured while fetching data");
    }
    finally {
        finallyCallback();
    }
};

const fetchMovieDetails = async (id, errorCallback) => {
    try {
        const response = await fetch(`https://www.omdbapi.com/?i=${id}&apikey=e15149d8&type=movie`);
        const data = await response.json();

        if (data.Response === "True") {
            return data;
        } else {
            throw new Error(data.Error);
        }
    } catch (err) {
        errorCallback("An error occured while fetching movie details");
    }
};