

function ErrorAlert({error, searchTerm}) {
    return (
        <div className="alert alert-dismissible alert-danger mt-4">
  <button type="button" className="btn-close" data-bs-dismiss="alert"></button>
  <strong>Oh snap!</strong> '{searchTerm}' resulted in '{error}' error.
</div>
    );
};

export default ErrorAlert;