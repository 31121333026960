

function MovieDetail({ movie }) {
    return (
        <>
            <div className="row">
                <div className="col-md-2">
                    <img src={movie.Poster} alt={movie.Title} width="100%" />
                </div>
                <div className="col-md-10">
                <div className="card mb-3">
                    <h3 className="card-header">{movie.Title}</h3>
                    <div className="card-body">
                        <h5 className="card-title">{movie.Genre}</h5>
                        <h6 className="card-subtitle text-muted">{movie.Runtime}</h6>
                    </div>
                    <div className="card-body">
                        <p className="card-text">{movie.Plot}</p>
                    </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><b>{movie.imdbRating}</b> IMDB</li>
                        {/* <li className="list-group-item"><b>{movie.Ratings[1].Value}</b> Rotten Tomatoes</li> */}
                    </ul>
                    <div className="card-body">
                        <a href={`https://www.imdb.com/title/${movie.imdbID}/`} target="_blank" rel="noreferrer" className="card-link">IMDB Website</a>
                        <a href={`https://www.youtube.com/results?search_query=${movie.Title}+trailer`} target="_blank" rel="noreferrer" className="card-link">Movie Trailer</a>
                    </div>
                    <div className="card-footer text-muted">
                    {movie.Year}
                    </div>
                </div>
                </div>
                {/* <div className="card">
  <div className="card-body">
    <h4 className="card-title">Card title</h4>
    <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6>
    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" className="card-link">Card link</a>
    <a href="#" className="card-link">Another link</a>
  </div>
</div> */}
            </div>

        </>
    )

}

export default MovieDetail;