

function Header() {
    return (
        <nav className="navbar navbar-expand-lg bg-dark" data-bs-theme="dark">
  <div className="container-fluid">
    <a className="navbar-brand" href="https://react-omdbapi.pages.dev/">IMDB MOVIES</a>
  </div>
</nav>
    )
}

export default Header;